import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <div className="mt-8 mb-2 text-xs tracking-wide text-center text-gray-400 uppercase">
        Nettside laget av{" "}
        <a
          href="mailto:m.a.sobergklyver@gmail.com"
          className="hover:text-[rgba(75,123,178,255)] transition duration-200 hover:font-bold"
        >
          MASK
        </a>
      </div>
      <div className="flex justify-center pb-2 mx-2 mb-4 space-x-4 text-xs text-center text-gray-400 uppercase md:justify-center">
        <p>Bodø Karateklubb</p>
        <p>
          <span className="whitespace-nowrap">Langstranda 5,</span>{" "}
          <span className="whitespace-nowrap">8003 BODØ</span>
        </p>
        <p>
          <span className="whitespace-nowrap">Org nr</span>{" "}
          <span className="whitespace-nowrap">984 182 317</span>
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
